<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <h1>
          Account aanvragen
        </h1>
      </v-col>

      <v-col cols="12" class="mb-4">
        Registreer met het e-mailadres dat gekoppeld is aan jouw Humanwise profiel.
      </v-col>

      <v-col cols="12" class="bm-4">
        <form v-on:submit.prevent="" v-on:keyup.enter="submit">
          <v-text-field
              v-model="email"
              label="Email"
              required
          ></v-text-field>

          <v-btn color="accent" class="mr-4" block @click="submit" :loading="loading" :disabled="loading">Account aanvragen</v-btn>
        </form>
      </v-col>

      <v-col cols="12" class="mt-4 mb-4">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="mb-4">
        Heb je nog geen Humanwise profiel? Vraag dan de benodigde informatie aan.
      </v-col>
      <v-col cols="12" class="mb-4">
        <v-btn color="accent" class="mr-4" @click="askInformation">Informatie aanvragen</v-btn>
      </v-col>
    </v-row>

    <snackbar-text text="Account aanvragen mislukt" bg-color="red" text-color="white" v-model="showError" />

    <snackbar-text :text="`Account aanvragen gelukt, er is een email gestuurd naar ${this.email} met een bevestigingscode.`"
                   bg-color="green"
                   text-color="white"
                   v-model="showSuccess"
                   :button="false"
                   :timeout="4000"
    />
  </v-container>
</template>

<script>

import apiClient from "@/services/apiClient";
import SnackbarText from "@/components/snackbar-text";

export default {
  name: "Register",
  components: {SnackbarText},
  data: () => ({
    email: null,
    showError: false,
    showSuccess: false,
    loading: false,
  }),
  methods: {
    submit() {
      if(this.loading){
        return "";
      }

      this.loading = true;

      let params = {
        email: this.email
      }

      apiClient.post("auth/register_token", params).then(response => {
        this.showSuccess = true;

        setTimeout(() => {
          this.$router.push({name: 'createPassword', params: params})
        }, 4000);
      }).catch(error => {
        this.showError = true;
        this.loading = false;
      });
    },
    askInformation() {
      this.$router.push({name: 'informationRequest'})
    },
  },
};
</script>
